import { Component, OnDestroy, OnInit } from '@angular/core';
import { BlocklistService } from '../../services/blocklist.service';
import { Subscription, forkJoin, map, tap } from 'rxjs';
import { Program } from '../../types/program';
import { faRemove } from '@fortawesome/free-solid-svg-icons';
import { ProgramsService } from '../../services/programs.service';

@Component({
  selector: 'app-blocklist',
  templateUrl: './blocklist.component.html',
  styleUrl: './blocklist.component.css'
})
export class BlocklistComponent implements OnInit, OnDestroy {
  private listSubscription?: Subscription
  programs: Program[] = []

  deleteIcon = faRemove

  constructor(private programService: ProgramsService, private blocklistService: BlocklistService){}

  private loadProgramsList(): void {
    this.listSubscription = this.programService.list()
      .pipe(map(res => {
        res.programs = res.programs.filter(prg => prg.blocklisted === true)
        return res
      })).subscribe(res => {
        this.programs = res.programs
      });
  }

  public handleDelete(program: Program){
    try {
      this.blocklistService.remove(program).subscribe(_ => this.loadProgramsList())
      
    } catch (e) {
      console.error(e);
    }
  }

  public handleExportClick(){
    this.blocklistService.list().subscribe(res => {
      const headers = ['Program Name', 'AdvertiserID'];
      const data = [headers, ...res.blocklist.map(item => [item.program_name, item.awin_mid].join(','))].join("\n")
      const blob = new Blob([data], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    })
  }

  public handleClearBlocklist(){
    if(confirm("Are you sure you want to clear the entire blocklist? This can't be undone.")){
      this.clearBlocklist()
    }
  }

  private clearBlocklist(){
    forkJoin(this.programs.map(program => this.blocklistService.remove(program))).subscribe(res => {
      console.log(res)
      this.loadProgramsList()
    })
  }

  public ngOnInit(): void {
    this.loadProgramsList()
  }
  
  public ngOnDestroy(): void {
    this.listSubscription?.unsubscribe();
  }

}
