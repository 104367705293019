import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.css'
})
export class FiltersComponent {
  @Input() filters: Filter[] = [] 
  @Output() filterClicked = new EventEmitter()

  public handleFilterClick(event: {display: string, item: string}){
    this.filterClicked.emit(event);
  }
}

export type Filter = {
  name: string
  options: string[]
}