<main>
    <section>
        <h2>Program Counts</h2>
        <!-- <app-button 
            color="#1d305f" 
            text="Export"
            (clickEvent)="handleProgramCountExportClick()"
        ></app-button> -->
    </section>
    <p>This chart depicts the split of programs by status. Note that this shows the <b>primary</b> status of the program, e.g if a program is both ineligble and blocked, it will show as blocked and not inelgibile.</p>
    <app-chart-wrapper
        id="programCounts"
        type="bar"
        [labels]="programCountsLabels"
        [datasets]="programCounts"
    ></app-chart-wrapper>
    <section>
        <h2>Issue Counts</h2>
        <!-- <app-button 
            color="#1d305f" 
            text="Export"
            (clickEvent)="handleIssueCountExportClick()"
        ></app-button> -->
    </section>
    <p>This chart depicts the volumes of issues present at the last run of eligible programs. Note that a program may have more than one issue present.</p>
    <app-chart-wrapper
        id="issueCounts"
        type="bar"
        [labels]="issueCountsLabels"
        [datasets]="issueCounts"
    ></app-chart-wrapper>
</main>