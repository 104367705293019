import { Routes } from '@angular/router';
import { UnhealthyComponent } from './views/unhealthy/unhealthy.component';
import { IgnoredComponent } from './views/ignored/ignored.component';
import { BlocklistComponent } from './views/blocklist/blocklist.component';
import { AnalyticsComponent } from './views/analytics/analytics.component';

const APP_TITLE = 'SingleView | Data Health'
const buildTitle = (title: string) => {
    return `${title} | ${APP_TITLE}`
}

export const routes: Routes = [
    {
      path: '',
      component: UnhealthyComponent,
      title: buildTitle('Program Alerts')
    },
    {
      path: 'ignored',
      component: IgnoredComponent,
      title: buildTitle('Ignored Programs')
    },   
    {
      path: 'blocklist',
      component: BlocklistComponent,
      title: buildTitle('Blocked Programs')
    },
    {
      path: 'analytics',
      component: AnalyticsComponent,
      title: buildTitle('Analytics')
    },
  ]
