import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrl: './nav.component.css'
})
export class NavComponent { 
  routes: RouteMatcher[] = [
    {
      display: 'Programs',
      items: [
        {
          display: 'Alerts',
          path: ''
        },
        {
          display: 'Ignored',
          path: 'ignored'
        },
        {
          display: 'Blocklist Export',
          path: 'blocklist'
        }
      ]
    },
    {
      display: 'Analytics',
      items: [
        {
          display: 'Dashboard',
          path: 'analytics'
        },
      ]
    }
  ]

  public openMenu?: string

  public constructor(private router: Router, private authService: AuthService){}

  handleClick(event: {
    display: string,
    item: string
  }): void{
    const path = this.routes.filter(r => r.display === event?.display)[0]?.items.filter(i => i.display === event?.item)[0]?.path
    if (typeof path !== 'undefined'){
     this.router.navigateByUrl(path)
    } else {
      console.error(`No path resolved for ${event?.display} ${event?.item}`)
    }
  }

  handleMenuOpened(menu: string){
    this.openMenu = menu;
    console.log(this.openMenu)
  }

  handleLogout(){
    this.authService.logOut()
  }
}

type RouteMatcher = {
  display: string,
  items: {
    display: string,
    path: string
  }[]
}