export type Env = {
    env: EnvType,
    adminAuthURL: string,
    logInURL: string,
    logOutURL: string,
    apiURL: string
}

export enum EnvType {
    Development,
    Local, 
    Production
}