import { Component } from '@angular/core';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../../services/analytics.service';
import { BarChartDataSets, PieChartDatasets } from '../../components/chart-wrapper/chart-wrapper.component';
@Component({
  selector: 'app-analytics',
  templateUrl: './analytics.component.html',
  styleUrl: './analytics.component.css'
})
export class AnalyticsComponent {
  private programCountsSubscription?: Subscription
  private issueCountsSubscription?: Subscription

  programCountsLabels: string[] = [];
  programCounts: BarChartDataSets = [];
  issueCountsLabels: string[] = [];
  issueCounts: BarChartDataSets = [];

  public constructor(private analyticsService: AnalyticsService) { }

  public handleProgramCountExportClick() {

  }

  public handleIssueCountExportClick() {

  }

  private loadProgramCounts(): void {
    this.programCountsSubscription = this.analyticsService.getProgramCounts().subscribe(res => {
      delete res.results.total;
      this.programCountsLabels = Object.keys(res.results).map(str => {
        const strArr = str.split('');
        strArr[0] = strArr[0]?.toUpperCase()
        return strArr.join('')
      });
      this.programCounts = [{
        label: "Program Counts",
        data: Object.entries(res.results).map(([_, v]) => v.toString()),
        backgroundColor: [
          'rgba(75, 192, 192, 0.2)',
          'rgba(255, 99, 132, 0.2)',
          'rgba(255, 159, 64, 0.2)',
          'rgba(255, 205, 86, 0.2)',
          'rgba(54, 162, 235, 0.2)',
        ],
      }]
    })
  }

  private loadIssueCounts(): void {
    this.issueCountsSubscription = this.analyticsService.getIssueCounts().subscribe(res => {
      this.issueCountsLabels = res.results.map(({ issue }) => {
        const strArr = issue.split('');
        strArr[0] = strArr[0]?.toUpperCase()
        return strArr.join('')
      });
      this.issueCounts = [{
        label: "Issue Counts",
        data: res.results.map(({ count }) => count.toString()),
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
        ],
      }]
    })
  }

  public ngOnInit(): void {
    this.loadProgramCounts()
    this.loadIssueCounts()
  }

  public ngOnDestroy(): void {
    this.programCountsSubscription?.unsubscribe();
    this.issueCountsSubscription?.unsubscribe();
  }

}
