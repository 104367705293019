<div>
    <app-dropdown 
        *ngFor="let route of routes" 
        [display]="route.display" 
        [items]="route.items | pluck:'display'" 
        (itemClicked)="handleClick($event)" 
        (menuOpened)="handleMenuOpened(route.display)"
        [activeItem]="route.items[0].display"
        [open]="openMenu === route.display">
    </app-dropdown>
    <p (click)="handleLogout()">Logout</p>
</div>