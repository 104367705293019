import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrl: './button.component.css'
})
export class ButtonComponent {
  @Input() text: string = 'Submit'
  @Input() color: string = 'blue'
  @Output() clickEvent = new EventEmitter()
  
  handleClick(){
    this.clickEvent.emit()
  }
}
