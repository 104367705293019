import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {ProgramsService} from "./services/programs.service";
import { UnhealthyItemComponent } from './components/unhealthy-item/unhealthy-item.component';
import { ButtonComponent } from './components/button/button.component';
import { UnhealthyProgramComponent } from './components/unhealthy-program/unhealthy-program.component';
import { UnhealthyComponent } from './views/unhealthy/unhealthy.component';
import { RouterLink, RouterLinkActive, RouterModule, RouterOutlet, provideRouter } from '@angular/router';
import { routes } from './app.routes';
import { NavComponent } from './components/nav/nav.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { PluckPipe } from './pipes/pluck.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IgnoredComponent } from './views/ignored/ignored.component';
import { AuthService } from './services/auth.service';
import { CookieModule, CookieService } from 'ngx-cookie'
import { HttpErrorInterceptor } from './injectables/http-error-interceptor';
import { BlocklistService } from './services/blocklist.service';
import { BlocklistComponent } from './views/blocklist/blocklist.component';
import { LoadingService } from './services/loading.service';
import { HttpRequestInterceptor } from './injectables/http-request-interceptor';
import { FiltersComponent } from './components/filters/filters.component';
import { AnalyticsComponent } from './views/analytics/analytics.component';
import { ChartWrapperComponent } from './components/chart-wrapper/chart-wrapper.component';

@NgModule({
  declarations: [
    AppComponent,
    ChartWrapperComponent,
    UnhealthyComponent,
    UnhealthyItemComponent,
    UnhealthyProgramComponent,
    IgnoredComponent,
    ButtonComponent,
    NavComponent,
    DropdownComponent,
    BlocklistComponent,
    FiltersComponent,
    AnalyticsComponent,
    PluckPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    RouterModule,
    FontAwesomeModule,
    CookieModule.withOptions(),
  ],
  providers: [
    ProgramsService,
    AuthService,
    BlocklistService,
    CookieService,
    LoadingService,
    provideRouter(routes),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}