import { Component, EventEmitter, Input, Output } from '@angular/core';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.css'
})
export class DropdownComponent {
  @Input() items: string[] = []
  @Input() display?: string;
  @Input() activeItem?: string;
  @Input() open = false;
  @Input() fontSize: string = "1.2rem";
  @Output() itemClicked = new EventEmitter();
  @Output() menuOpened = new EventEmitter();

  downIcon = faCaretDown
  upIcon = faCaretRight

  public constructor(){
    this.activeItem = this.items[0];
  }

  handleItemClick(display?: string, item?: string){
    this.activeItem = item;
    this.toggleOpen()
    this.itemClicked.emit({
      display,
      item
    })
  }

  toggleOpen(){
    this.open = !this.open;
    this.menuOpened.emit()
  }
}
