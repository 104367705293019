<main class="main">
    <div class="list">
        <app-filters [filters]="alertFilters" (filterClicked)="handleFilter($event)"></app-filters>
        <app-unhealthy-item 
            *ngFor="let program of programs" 
            [program]="program"
            (programClick)="handleProgramSelection($event)"
            [active]="program.programcode === activeProgram?.programcode"
        >
        </app-unhealthy-item>
    </div>
    <app-unhealthy-program 
        [program]="activeProgram"
        (programIgnored)="handleIgnoredProgram($event)"
        (programBlocklisted)="handleProgramBlocklisted($event)"
    >
    </app-unhealthy-program>
</main>