import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { Observer } from 'rxjs';
import { environment } from '../../environments/environment';
import { EnvType } from '../../environments/Env';

const SINGLEVIEW_TOKEN = 'SINGLEVIEW_TOKEN'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  adminAuthURL = environment.adminAuthURL;
  logInURL = environment.logInURL;
  logOutURL = environment.logOutURL;
  constructor(private cookieService: CookieService, private http: HttpClient, private router: Router) { 

  }

  public logOut() {
    if (environment.env === EnvType.Local){
      return
    }
    const chain: (() => void)[] = [
      () => this.clearLocalStorageToken()
    ]
    
    for (let provider of chain){
      provider()
    }

    window.location.href = this.logOutURL
  }

  public unauthorized(){
    this.router.navigateByUrl('/forbidden')
  }

  public unauthenticated(){
    this.logOut()
  }

  private storeToken(token: string) {
    localStorage.setItem(SINGLEVIEW_TOKEN, token);
  }  
  
  private fetchUrlToken(): string | undefined {
    const url = new URL(window.location.href);
    return url.searchParams.get('token') ?? undefined
  }

  private fetchCookieToken(): string | undefined {
    return this.cookieService.get(SINGLEVIEW_TOKEN)
  }

  private fetchLocalStorageToken(): string | undefined {
    return localStorage.getItem(SINGLEVIEW_TOKEN) ?? undefined;
  }

  private clearLocalStorageToken(): void {
    localStorage.removeItem(SINGLEVIEW_TOKEN);
  }

  public resolveToken(): string | undefined {
    const chain: (() => string | undefined)[] = [
      () => this.fetchCookieToken(),
      () => this.fetchLocalStorageToken(),
      () => this.fetchUrlToken()
    ]
    
    for (let provider of chain){
      if (typeof provider() !== 'undefined' ){
        return provider()!
      }
    }

    return undefined
  }

  public check(): void {
    if (environment.env === EnvType.Local) {
      return
    }
    const token = this.resolveToken()
    if (!token) {
      this.unauthenticated()
    } else {
      const observer: Observer<HttpResponse<Object>> = {
        next: function (value: HttpResponse<Object>): void {},
        error: (err: HttpErrorResponse) =>{
        },
        complete: (): void => {
          this.storeToken(token)
        }
      }
      this.http.get(
        `${this.adminAuthURL}?token=${token}`, 
        { 
          responseType: "text" as "json",
          observe: 'response' 
        }
      ).subscribe(observer)
    }
  }
}