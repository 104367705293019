<h3>Ignored Programs</h3>
<div class="list-container">
    <header>
        <p>Program</p>
        <p>Merchant ID</p>
        <p>Name</p>
        <p>Ignored From</p>
        <p>Ignore Until</p>
    </header>
    <section *ngFor="let program of programs">
        <p>{{program.programcode}}</p>
        <p>{{program.awin_mid}}</p>
        <p>{{program.program_name}}</p>
        <p>{{program.ignored_from | date: 'yyyy-M-dd' }}</p>
        <p><span *ngIf="program.ignore_until">{{program.ignore_until | date: 'yyyy-M-dd' }}</span></p>
        <a (click)="handleDelete(program)"><fa-icon [icon]="deleteIcon"></fa-icon></a>
    </section>
</div>