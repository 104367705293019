import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ChartDataset, ChartType, ChartTypeRegistry, DefaultDataPoint, Point } from 'chart.js';
import Chart from 'chart.js/auto';


@Component({
  selector: 'app-chart-wrapper',
  templateUrl: './chart-wrapper.component.html',
  styleUrl: './chart-wrapper.component.css'
})
export class ChartWrapperComponent<
  TType extends keyof ChartTypeRegistry = keyof ChartTypeRegistry,
  TData = DefaultDataPoint<TType>,
> implements OnInit, OnChanges, OnDestroy {
  @Input() id?: string;
  @Input() labels: string[] = [];
  @Input() datasets?: ChartDataset<keyof ChartTypeRegistry, TData>[];
  @Input() type: ChartType = 'line';
  @Input() containerStyle: string = "";
  chart?: Chart<keyof ChartTypeRegistry, TData, string>;
height: any;

  public constructor(private elementRef: ElementRef) { }

  createChart() {
    if (!this.datasets) {
      throw new Error("No dataset provided")
    }
    if (this.chart){
      this.chart.destroy();
    }
    let htmlRef = this.elementRef.nativeElement.querySelector(`#${this.id}`);
    this.chart = new Chart(htmlRef, {
      type: this.type,
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          
        },
        ...this.resolveChartTypeConfig()
      }
    });
  }

  private resolveChartTypeConfig(): /*Partial<ChartConfiguration>*/ any {
    return {
      "pie": {
          plugins: {
            legend: {
              position: "right"
            }
          }
      },
      "bar": {
        plugins: {
          legend: {
            display: false
          }
        }
      },
      "line": {

      },
      "bubble": {

      },
      "doughnut": {

      },
      "polarArea": {

      },
      "radar": {

      },
      "scatter": {

      }
    }[this.type]
  }

  ngOnInit(): void {
    this.createChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['labels'] || changes['dataset']) {
      this.createChart()
    }
  }

  ngOnDestroy(): void {
    this.chart?.destroy()
  }
}

// export type BarChartDataSets = {
//   label?: string;
//   data: string[] 
//   backgroundColor: string[],
//   borderColor:  string[],
//   borderWidth: number
// }[]

// export type PieChartDatasets = {
//   label?: string;
//   data: string[] 
//   backgroundColor: string[],
//   hoverOffset: number
// }[

export type BarChartDataSets = ChartDataset<"pie", string[]>[]
export type PieChartDatasets = ChartDataset<"pie", string[]>[]
export type LineChartDatasets = ChartDataset<"line", (number | Point | null)[]>[]
