import { Injectable } from '@angular/core';
import { EMPTY, Observable, catchError, map } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

/**
 * This class is for intercepting http requests. When a request starts, we set the loadingSub property
 * in the LoadingService to true. Once the request completes and we have a response, set the loadingSub
 * property to false. If an error occurs while servicing the request, set the loadingSub property to false.
 * @class {HttpRequestInterceptor}
 */
@Injectable({
    providedIn: 'root'
})
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private loadingService: LoadingService,
    private authService: AuthService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingService.setLoading(true, request.url);
    return next.handle(request)
        .pipe(
            map<HttpEvent<any>, HttpEvent<any>>((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                    this.loadingService.setLoading(false, request.url);
                }                
                return evt;
            })
        ).pipe(
            catchError((error: HttpErrorResponse) => {
                if (error.error instanceof Error) {
                    console.error('An error occurred:', error.error.message);
                } else {
                    console.error(`HTTP error status received: ${error.status}, body was: ${error.error}`);
                    if (error.status === 401){
                        this.authService.unauthenticated()
                    } else if (error.status === 403){
                        this.authService.unauthorized()
                    }
                }
                return EMPTY;
            })
        )
  }
}