<section *ngIf="program">
    <div class="header">
        <h1>{{program.program_name}}</h1>
        <div class="buttons">
            <app-button 
                color="#c83e4ea6" 
                [text]="markedForBlocklist ? 'Unmark for Blocklist' : 'Mark for Blocklist'"
                (clickEvent)="handleToggleBlocklist(program)"
            ></app-button>
            <app-button 
                color="#f4b860" 
                text="Snooze for 7 days"
                (clickEvent)="handleSnoozeClick(program)"
            ></app-button>
            <app-button 
                color="#1d305f" 
                text="Ignore"
                (clickEvent)="handleIgnoreClick(program)"
            ></app-button>
            
        </div>
    </div>
    <div class="details">
        <h2>Program: {{program.programcode}}</h2>
        <h2>Merchant ID: {{program.awin_mid}}</h2>
    </div>

    <h2>Health Failures</h2>
    <div class="date-tabs">
        <p 
            class="date-tab" 
            *ngFor="let day of program?.days"
            (click)="handleDailyTabClick(day.date)"
            [ngClass]="activeDailyTab == day.date ? 'active': 'inactive'"
        >
            {{ day.date | date: 'yyyy-M-dd' }}
        </p>
    </div>
    <div 
        class="tab" 
        *ngFor="let day of program?.days" 
        [ngClass]="activeDailyTab == day.date ? 'active': 'inactive'"
    >
        <div *ngFor="let failure of day.failures">
            <p class="warning-text">{{failure.issue}}:</p>
            <p>{{failure.flag}}</p>
            <p class="supplementary-text">{{failure.action}}</p>
        </div>
        <p>{{( day.failures?.length ?? 0 ) === 0 ? 'None' : ''}}</p>
    </div>

    <h2>Data Visualisation</h2>
    <h3>Sales to Page Lands - Last 7 days</h3>
    <app-chart-wrapper id="SalesPageLands" type="line" [labels]="chartLabels" [datasets]="chartData" style="position: relative; height:50vh; width:auto"></app-chart-wrapper>
    <h3>Sales Intersection & Channel Proportion - Last 7 days</h3>
    <app-chart-wrapper id="Percents" type="line" [labels]="pcChartLabels" [datasets]="pcChartData" style="position: relative; height:50vh; width:auto"></app-chart-wrapper>
    
</section>

<section *ngIf="!program">
    <p></p>
</section>

