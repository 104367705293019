import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { BlocklistService } from './blocklist.service';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {
    baseUrl = `${environment.apiURL}/analytics/`;
    headers = new HttpHeaders()

    constructor(private http: HttpClient, private authService: AuthService, private blocklistService: BlocklistService) {
        this.headers = this.headers.set('Authorization', `Bearer ${this.authService.resolveToken()}`)
    }

    getProgramCounts(): Observable<GetProgramCountsResult> {
        return this.http.get<GetProgramCountsResult>(`${this.baseUrl}/programs`, { headers: this.headers })
    }

    getIssueCounts(): Observable<GetIssueCountsResult> {
        return this.http.get<GetIssueCountsResult>(`${this.baseUrl}/issues`, { headers: this.headers })
    }
}

export type GetProgramCountsResult = {
    results: {
        unhealthy: number
        total?: number
    }
}

export type GetIssueCountsResult = {
    results: {
        issue: string
        count: number
    }[]
}