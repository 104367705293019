<section>
    <p>Filters:</p>
    <app-dropdown *ngFor="let filter of filters"
        [display]="filter.name" 
        [items]="filter.options"
        [activeItem]="filter.options[0]"
        fontSize="1rem"
        (itemClicked)="handleFilterClick($event)"
    >
    </app-dropdown>
</section>