import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable, forkJoin, map, of, take} from "rxjs";
import {Program, ProgramRecords} from "../types/program";
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment';
import { BlocklistService } from './blocklist.service';

@Injectable({
  providedIn: 'root'
})
export class ProgramsService {
  baseUrl = `${environment.apiURL}/programs/`;
  headers = new HttpHeaders()
  private filterMap: FilterMap = {
    [AlertFilter.Issues]: 'issues'
  }
  filters: Filter = {}

  private programs: Program[] = []

  constructor(private http: HttpClient, private authService: AuthService, private blocklistService: BlocklistService) {
    this.headers = this.headers.set('Authorization', `Bearer ${this.authService.resolveToken()}`)
  }

  list(cache: boolean = false): Observable<ListProgramsResult>{
    if (cache === true && this.programs.length) {
      return of({programs: this.programs})
    }

    const searchParams = new URLSearchParams(this.filters)
    const url = new URL(`${this.baseUrl}/unhealthy?${searchParams.toString()}`)
  
    return forkJoin([
      this.http.get<ListProgramsResult>(url.toString(), { headers: this.headers }),
      this.blocklistService.list()
    ]).pipe(
      map(res => {
        res[0].programs = res[0].programs.map(prg => {
          const blocklisted = res[1]?.blocklist?.filter(blocked => blocked.programcode === prg.programcode) ?? []
          prg.blocklisted = blocklisted.length > 0 ? true : false
          return prg
        })
        return res[0]
      })
    )
  }

  getRecords(program: Program): Observable<GetRecordsResult>{
    return this.http.get<GetRecordsResult>(
      `${this.baseUrl}/unhealthy/${program.programcode}?start_date=${program.date_range.start_date}&end_date=${program.date_range.end_date}`,
      { headers: this.headers }
    );
  }
  
  listIgnored(): Observable<ListProgramsResult>{
    return this.http.get<ListProgramsResult>(`${this.baseUrl}/ignored`, { headers: this.headers });
  }

  ignore(program: Program): Observable<IgnoreProgramResult> {
    return this.http.patch<IgnoreProgramResult>(
      `${this.baseUrl}/ignore`, 
      { programcode: program.programcode },
      { headers: this.headers }
    )
  } 

  unignore(program: Program): Observable<IgnoreProgramResult> {
    return this.http.patch<IgnoreProgramResult>(
      `${this.baseUrl}/unignore`, 
      { programcode: program.programcode },
      { headers: this.headers }
    )
  } 

  snooze(program: Program): Observable<IgnoreProgramResult> {
    const date = new Date();
    const ignoreDate = new Date(date.setDate(date.getDate() + 7));
    const ignoreUntil = ignoreDate.toLocaleDateString('en-CA', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric'
    })
    return this.http.patch<IgnoreProgramResult>(
      `${this.baseUrl}/ignore`, 
      {
        programcode: program.programcode,
        ignore_until: ignoreUntil
      },
      { headers: this.headers }
    )
  }
}

export type ListProgramsResult = {
    programs: Program[]
}

export type GetRecordsResult = {
  records: ProgramRecords[],
}

export type IgnoreProgramResult = {}

export enum AlertFilter {
  Issues = 'Issue'
}

type FilterMap = Record<AlertFilter, string>
type FilterKey = FilterMap[keyof FilterMap]
type FilterValue = string
type Filter = Record<FilterKey, FilterValue>