<section 
[ngClass]="{'active': active, 'blocklisted': markedForBlocklist}"
(click)="handleProgramClick(program)"
>
    <div class="header">
        <h1>{{program?.program_name ?? 'Program Name'}}</h1>
    </div>
    <div class="details">
        <h2>Program: {{program?.programcode}}</h2>
        <h2>Merchant ID: {{program?.awin_mid}}</h2>
    </div>
    
    <div class="failures">
        <div class="tags">
            <p class="failure-tag" *ngFor="let f of failures ?? []">
                {{ f }}
            </p>
        </div>
    </div>
</section>

