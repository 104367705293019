<div [style]="{fontSize: fontSize}">
    <a (click)="toggleOpen()">
        <p>{{ display }} 
            <fa-icon *ngIf="open" [icon]="upIcon"></fa-icon>
            <fa-icon *ngIf="!open" [icon]="downIcon"></fa-icon>
        </p>
        
    </a>
    <section *ngIf="open">
        <ul>
            <li *ngFor="let item of items" (click)="handleItemClick(display, item)" [ngClass]="item === activeItem ? 'active' : ''">{{item}}</li>
        </ul>
    </section>
</div>
