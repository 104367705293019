<header>
    <h3>Blocklist Export</h3>
    <div class="buttons">
        <app-button 
            color="#c83e4ea6" 
            text="Clear"
            (clickEvent)="handleClearBlocklist()"
        ></app-button>
        <app-button 
            color="#1d305f" 
            text="Export"
            (clickEvent)="handleExportClick()"
        ></app-button>
    </div>
   
</header>
<main class="list-container">
    <header>
        <p>Program</p>
        <p>Merchant ID</p>
        <p>Name</p>
    </header>
    <section *ngFor="let program of programs">
        <p>{{program.programcode}}</p>
        <p>{{program.awin_mid}}</p>
        <p>{{program.program_name}}</p>
        <a (click)="handleDelete(program)"><fa-icon [icon]="deleteIcon"></fa-icon></a>
    </section>
</main>