import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramsService } from '../../services/programs.service';
import { Subscription } from 'rxjs';
import { Program } from '../../types/program';
import { faCancel, faCross, faRemove, faTrash, faX } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-ignored',
  templateUrl: './ignored.component.html',
  styleUrl: './ignored.component.css'
})
export class IgnoredComponent {
  programs: Program[] = [];
  listSubscription?: Subscription;
  
  deleteIcon = faRemove
  
  constructor(public service: ProgramsService) {}

  private loadProgramsList(): void {
    this.listSubscription = this.service.listIgnored()
      .subscribe(res => {
        this.programs = res.programs
    });
  }

  handleDelete(program: Program){
    try {
      this.service.unignore(program!).subscribe(_ => this.loadProgramsList())
    } catch (e) {
      console.error(e);
    }
  }

  ngOnInit(): void {
    this.loadProgramsList();
  }

  ngOnDestroy(): void {
    this.listSubscription?.unsubscribe();
  }
}
