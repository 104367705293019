import { Injectable } from '@angular/core';
import { Program, ProgramRef } from '../types/program';
import { Observable, Subscription, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

const LOCAL_STORAGE_KEY = 'blocklistProgramMap'

@Injectable({
  providedIn: 'root'
})
export class BlocklistService {
  baseUrl = `${environment.apiURL}/programs/blocklist`;
  headers = new HttpHeaders()

  programSubscription?: Subscription
  programMap: Record<string, Program> = {}
  constructor(private http: HttpClient, private authService: AuthService) {
    try {
      this.programMap = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) ?? '{}')
    } catch (e: unknown){
      console.error(e as Error)
    }
    
    this.headers = this.headers.set('Authorization', `Bearer ${this.authService.resolveToken()}`)
  }
  
  public add(program: Program): Observable<AddProgramBlocklistResult> {
    return this.http.put<AddProgramBlocklistResult>(
      `${this.baseUrl}`, 
      { programcode: program.programcode },
      { headers: this.headers }
    )
  } 

  public remove(program: Program): Observable<AddProgramBlocklistResult> {
    return this.http.delete<AddProgramBlocklistResult>(
      `${this.baseUrl}`, 
      {
        body: { programcode: program.programcode },
        headers: this.headers 
      }
    )
  } 

  public list(): Observable<ListProgramsResult> {
    return this.http.get<ListProgramsResult>(`${this.baseUrl}`, { headers: this.headers });
  }
}

export type ListProgramsResult = {
  blocklist: Program[]
}

export type AddProgramBlocklistResult = {}
export type RemoveProgramBlocklistResult = {}