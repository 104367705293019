import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { Program, ProgramRecords } from '../../types/program';
import { Subscription, of } from 'rxjs';
import { ProgramsService } from '../../services/programs.service';
import { BlocklistService } from '../../services/blocklist.service';
import { LineChartDatasets } from '../chart-wrapper/chart-wrapper.component';

@Component({
  selector: 'app-unhealthy-program',
  templateUrl: './unhealthy-program.component.html',
  styleUrl: './unhealthy-program.component.css'
})
export class UnhealthyProgramComponent implements OnChanges {
  @Input() program?: Program
  @Output() programIgnored = new EventEmitter()
  @Output() programBlocklisted = new EventEmitter()

  markedForBlocklist: boolean = false;
  records?: ProgramRecords[]
  recordsSubscription?: Subscription;
  pcChartLabels: string[] = [];
  pcChartData: LineChartDatasets = [];
  chartLabels: string[] = [];
  chartData: LineChartDatasets = [];
  activeDailyTab?: string

  public constructor(private service: ProgramsService, private blocklistService: BlocklistService){
  }

  private prepareRecordsForPercentages(records: ProgramRecords[]): {
    labels: string[],
    datasets: LineChartDatasets
  }{
    const labels = records.map(record => new Date(record.date).toLocaleDateString());
    return {
      labels: labels ?? [],
      datasets: [
        {
          label: 'Awin Sales Intersection %',
          data: records?.map(record => record.awin_sales_intersection_percent) ?? [],
        },
        {
          label: 'Affiliate Proportion %',
          data: records?.map(record => record. affiliate_proportion_percent) ?? []
        },
        {
          label: 'Unidentified Referrers %',
          data: records?.map(record => record.unidentified_referrers_percent) ?? []
        },
        {
          label: 'Direct Last Click Sales %',
          data: records?.map(record => record.direct_last_click_sales_percent) ?? []
        }
      ]
    };
  }

  private prepareRecordsForSalesPageLands(records: ProgramRecords[]): {
    labels: string[],
    datasets: LineChartDatasets
  }{
    const labels = records.map(record => new Date(record.date).toLocaleDateString());
    return {
      labels: labels ?? [],
      datasets: [
        {
          label: 'Sales',
          data: records?.map(record => record.sales) ?? []
        },
        {
          label: 'Page Lands',
          data: records?.map(record => record.page_lands) ?? []
        },
        {
          label: 'Missing Order IDs',
          data: records?.map(record => record.missing_order_id_count) ?? []
        }
      ]
    };
  }

  private fetchProgramRecords(program: Program): void {
    this.recordsSubscription = this.service.getRecords(program)
      .subscribe(res => {

        const { labels: pcLabels, datasets: pcDatasets } = this.prepareRecordsForPercentages(res.records)
        this.pcChartLabels = pcLabels;
        this.pcChartData = pcDatasets;

        const { labels, datasets } = this.prepareRecordsForSalesPageLands(res.records)
        this.chartLabels = labels;
        this.chartData = datasets;
        
        this.records = res.records
      })
   
  }

  public handleDailyTabClick(date: string): void {
    this.activeDailyTab = date
  }
 
  public handleToggleBlocklist(program: Program): void {
    try {
      if (this.program?.blocklisted){
        this.blocklistService.remove(program).subscribe(res => {
          this.dispatchVisualChanges(program)
        })
      } else {
        this.blocklistService.add(program).subscribe(res => {
          this.dispatchVisualChanges(program)
        })
      }

    } catch (e) {
      console.error(e);
    }
  }

  private dispatchVisualChanges(program?: Program){
    this.markedForBlocklist = !this.markedForBlocklist
    this.programBlocklisted.emit(program)
  }

  public handleIgnoreClick(program?: Program): void {
    try {
      this.service.ignore(program!).subscribe(_ => this.programIgnored.emit(program))
    } catch (e) {
      console.error(e);
    }
  }

  public handleSnoozeClick(program?: Program): void {
    try {
      this.service.snooze(program!).subscribe(_ => this.programIgnored.emit(program))
    } catch (e) {
      console.error(e);
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const program: Program | undefined = changes['program']?.
    currentValue

    if (program){
      this.activeDailyTab = program.days[0]?.date
      this.fetchProgramRecords(program)
      this.markedForBlocklist = program.blocklisted ?? false;
    }
  }

  public ngOnInit(): void {
    if (this.program){
      this.fetchProgramRecords(this.program)
    }
  }

}
