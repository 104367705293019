<!-- Loading spinner -->
<div *ngIf="loading" class="loading-container flex-content-center">
  <fa-icon [icon]="loadingSpinner" id="loading"></fa-icon>
</div>

<main class="main">
  <header>
    <img src="/assets/singleview-logo.svg" height="40px"/>
    <h2>Data Health</h2>
    <app-nav></app-nav>
    <div></div>
  </header>
  <router-outlet></router-outlet>
</main>

