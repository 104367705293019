import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Program } from '../../types/program';
import { BlocklistService } from '../../services/blocklist.service';
import { Subscription, last, of } from 'rxjs';

@Component({
  selector: 'app-unhealthy-item',
  templateUrl: './unhealthy-item.component.html',
  styleUrl: './unhealthy-item.component.css'
})
export class UnhealthyItemComponent implements OnInit, OnChanges {
  @Input() program?: Program
  @Input() active: boolean = false;
  @Output() programClick = new EventEmitter()
  markedForBlocklist = false
  failures?: Failures

  public constructor(){}

  private parseFailures(): void {
    const failures: Failures = []
    const lastDay = this.program?.days[this.program?.days.length - 1]
    if (lastDay) {
      for (let failure of lastDay.failures ?? []){
        failures.push(failure.issue);
      }
    }
    this.failures = [...new Set(failures)]
  }

  public handleProgramClick(program?: Program){
    this.programClick.emit(program)
  }

  public ngOnInit(): void {
    this.parseFailures()
    if (this.program){
      this.markedForBlocklist = this.program.blocklisted ?? false
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    const program: Program | undefined = changes['program']?.
    currentValue

    if (program){
      this.markedForBlocklist = program.blocklisted ?? false;
    }
  }

}

type Failures = string[]