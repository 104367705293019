import { Component, OnDestroy, OnInit } from '@angular/core';
import { Program } from '../../types/program';
import { Subscription, of } from 'rxjs';
import { AlertFilter, ProgramsService } from '../../services/programs.service';
import { Filter } from '../../components/filters/filters.component';

@Component({
  selector: 'app-unhealthy',
  templateUrl: './unhealthy.component.html',
  styleUrl: './unhealthy.component.css'
})
export class UnhealthyComponent implements OnInit, OnDestroy {
  programs: Program[] = [];
  activeProgram?: Program;
  listSubscription?: Subscription;
  activeSubscription?: Subscription;
  alertFilters: Filter[] = filters
  
  constructor(public service: ProgramsService) {}

  private loadProgramsList(cached: boolean = false): void {
    this.listSubscription = this.service.list(cached).subscribe(res => {
      this.programs = res.programs
      this.setActiveProgram(this.activeProgram ?? res.programs[0])
    });
  }

  private setActiveProgram(program?: Program): void {
    this.activeSubscription = of(program)
      .subscribe(res => this.activeProgram = res)
  }

  public handleProgramSelection(program?: Program){
    this.setActiveProgram(program)
  }
  
  public handleIgnoredProgram(program: Program){
    this.listSubscription = of(this.programs.filter(prg => prg.programcode !== program.programcode))
      .subscribe(res => {
        this.programs = res
        this.activeProgram = res[0]
      })
  }

  public handleProgramBlocklisted(_: Program){
    this.loadProgramsList(true)
  }

  public handleFilter(event: {display: string, item: string}) {
    this.dispatchFilter(event.display, event.item)
  }

  private dispatchFilter(name: string, option: string){
    let filter: string|undefined
    let value: string|undefined
    if (name === AlertFilter.Issues) {
      filter = 'issue'
      value = option === "All" ? "" : option
    }

    if (filter && typeof value !== 'undefined') {
      this.service.filters[filter] = value
      this.loadProgramsList()
    }
    
  }

  ngOnInit(): void {
    this.loadProgramsList();
  }

  ngOnDestroy(): void {
    this.listSubscription?.unsubscribe();
    this.activeSubscription?.unsubscribe();
  }
}

const filters: Filter[] = [
  {
    name:  AlertFilter.Issues,
    options: [
      "All",
      "No Tag Name",
      "Blocked Domains",
      "Missing SV Tag",
      "Missing Order IDs",
      "Affiliate Conditional Firing",
      "Incorrect Homepage Loading",
      "Missing SV Appends",
      "Critical Awin Match Rate",
      "Warning Awin Match Rate",
      "Unidentified Tracking Issue",
    ]
  }
]
